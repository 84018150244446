import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CoverURL } from "../../utils/endpoints";
import "./style.css";

const DetailModal = ({ show, handleClose, handleComplete, item }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Order Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-start">
          <div className="d-flex flex-column gap-2  align-items-start justify-content-center w-100">
            {item?.orders?.map((itm, inx) => {
              return (
                <div key={inx}>
                  <img
                    src={
                      itm?.product?.cover ? CoverURL + itm?.product?.cover : ""
                    }
                    className="imgStyle"
                  />
                  <label className="detail-prod-title ms-2">
                    {itm?.product?.title}
                  </label>
                  <label className="mx-2 x-label">X</label>
                  <label>{itm?.quantity}</label>
                </div>
              );
            })}
          </div>
          <div className="d-flex flex-column gap-2 mt-4 align-items-start justify-content-center w-100">
            <label className="detail-prod-user-title">User Details</label>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex align-items-center justify-content-center">
                <label className="detail-prod-name">Firstname: </label>
                <label className="ms-2 detail-prod-name-bold">
                  {item?.firstname}
                </label>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <label className="detail-prod-name">Lastname: </label>
                <label className="ms-2 detail-prod-name-bold">
                  {item?.lastname}
                </label>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <label className="detail-prod-name">Email: </label>
              <label className="ms-2 detail-prod-name-bold">
                {item?.email}
              </label>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <label className="detail-prod-name">Phonenumber: </label>
              <label className="ms-2 detail-prod-name-bold">
                {item?.phonenumber}
              </label>
            </div>
            {item?.sideNotes ? (
              <div className="d-flex align-items-center justify-content-center">
                <label className="detail-prod-name">Side Notes: </label>
                <label className="ms-2 detail-prod-name-bold">
                  {item?.sideNotes}
                </label>
              </div>
            ) : null}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleClose();
            handleComplete();
          }}
        >
          Complete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DetailModal;
