import "./App.css";
import AuthRoute from "./shared/routes/authRoute";
import Toast from "./shared/components/toast";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import { initialConfig } from "./shared/utils/interceptor";

function App() {
  useEffect(() => {
    initialConfig();
  }, []);
  return (
    <div className="App">
      <AuthRoute />
      <Toast />
    </div>
  );
}

export default App;
