import React, { useEffect, useState } from "react";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import { Spinner } from "react-bootstrap";
import { toastMessage } from "../toast";
import "./style.css";
import { CoverURL } from "../../utils/endpoints";
import { DeleteProduct, UpdateProduct } from "../../services/productService";
import ProductDetailModal from "../../modals/productDetail";

const ProductCard = ({ item, allProducts, setAllProducts }) => {
  const [deleteloading, setDeleteLoading] = useState(false);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = () => {
    setDeleteLoading(true);
    DeleteProduct(item?._id)
      .then((data) => {
        if (data) {
          let temp = [...allProducts];
          var filterArr = temp.filter((itm) => {
            return itm?._id != item?._id;
          });
          setAllProducts(filterArr);
          toastMessage("success", "Product deleted");
        }
      })
      .catch((err) => {
        console.log("ERROR", err);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };
  const handleFeature = () => {
    setCompleteLoading(true);
    setStatus(!status);
    UpdateProduct(item?._id, { feature: !item?.feature })
      .then((data) => {
        if (data) {
          if (item?.feature) {
            toastMessage("success", "Product removed from feature");
          } else {
            toastMessage("success", "Product Featured");
          }
        }
      })
      .catch((err) => {
        console.log("ERROR", err);
      })
      .finally(() => {
        setCompleteLoading(false);
      });
  };
  useEffect(() => {
    setStatus(item?.feature);
  }, [item?._id]);
  return (
    <div className="col-12 justify-content-between d-flex flex-column flex-lg-row align-items-center blog-card-container mt-4">
      <div className="d-flex align-items-center col-12 col-lg-6">
        <img
          src={item?.cover ? CoverURL + item?.cover : ""}
          className="prod-card-img"
        />
        <div className="d-flex flex-column">
          <h5 className="blog-card-subtitle ms-2 d-flex justify-content-start align-items-center ">
            {item?.title}
          </h5>
          <span className="blog-card-subtitle ms-2">
            {moment(item?.createdAt).fromNow()}
          </span>
        </div>
      </div>
      <div className="d-flex flex-row mt-3 mt-lg-0 flex-lg-column align-items-end col-12 col-lg-6">
        <button
          className="secndry-btn"
          disabled={deleteloading || completeLoading}
          onClick={handleShow}
        >
          <>
            <FeatherIcon icon={"eye"} className="icon-style me-1" />
            View
          </>
        </button>
        <button
          className="secndry-btn"
          disabled={deleteloading || completeLoading}
          onClick={handleFeature}
        >
          {completeLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>{!status ? "Feature" : "Remove from feature"}</>
          )}
        </button>
        <button
          className="secndry-btn"
          disabled={deleteloading || completeLoading}
          onClick={handleDelete}
        >
          {deleteloading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>
              <FeatherIcon icon={"trash"} className="icon-style me-1" />
              Delete
            </>
          )}
        </button>
      </div>
      <ProductDetailModal
        show={show}
        handleClose={handleClose}
        handleFeature={handleFeature}
        item={item}
      />
    </div>
  );
};

export default ProductCard;
