import React, { useEffect, useState } from "react";
import Header from "../../shared/components/header";
import { GetAllProducts } from "../../shared/services/productService";
import ProductCard from "../../shared/components/productCard";
import { Spinner } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import AddProductModal from "../../shared/modals/addProduct";

const Products = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleShow = () => {
    setShowAddProduct(true);
  };

  const handleClose = () => {
    setShowAddProduct(false);
  };

  const handleProducts = () => {
    setLoading(true);
    GetAllProducts()
      .then(({ data }) => {
        setAllProducts(data);
      })
      .catch((err) => {
        console.log("ERROR", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    handleProducts();
  }, []);
  return (
    <>
      <Header active="Products" />
      <div className="container  py-4">
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="login-title mt-5 mb-4 text-align-left">Products</h2>
          <button className="secndry-btn" onClick={handleShow}>
            <FeatherIcon icon={"plus"} className="icon-style me-1" />
            Add Product
          </button>
        </div>

        {loading ? (
          <Spinner size="lg" animation="border" style={{ color: "#145777" }} />
        ) : allProducts?.length > 0 ? (
          allProducts?.map((item, key) => {
            return (
              <ProductCard
                item={item}
                key={key}
                allProducts={allProducts}
                setAllProducts={setAllProducts}
              />
            );
          })
        ) : (
          <label className="no-order-label">No Products Found!</label>
        )}
      </div>
      <AddProductModal
        show={showAddProduct}
        handleClose={handleClose}
        allProducts={allProducts}
        setAllProducts={setAllProducts}
      />
    </>
  );
};

export default Products;
