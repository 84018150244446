import React, { useEffect, useState } from "react";
import { GetAllOrders } from "../../shared/services/orderService";
import Header from "../../shared/components/header";
import { Spinner } from "react-bootstrap";
import OrderCard from "../../shared/components/orderCard/";
import "./style.css";

const Orders = () => {
  const [allorders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAllOrders = () => {
    setLoading(true);
    GetAllOrders()
      .then(({ data }) => {
        setAllOrders(data);
      })
      .catch((err) => {
        console.log("ERROR", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllOrders();
  }, []);
  return (
    <>
      <Header active="Orders" />
      <div className="container  py-4">
        <h2 className="login-title mt-5 mb-4 text-align-left">Orders</h2>
        {loading ? (
          <Spinner size="lg" animation="border" style={{ color: "#145777" }} />
        ) : allorders?.length > 0 ? (
          allorders?.map((item, key) => {
            return (
              <OrderCard
                item={item}
                key={key}
                allorders={allorders}
                setAllOrders={setAllOrders}
              />
            );
          })
        ) : (
          <label className="no-order-label">No Orders Yet!</label>
        )}
      </div>
    </>
  );
};

export default Orders;
