import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { GetAllCount } from "../../services/orderService";
import "./style.css";

const InforCard = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const getOrdersCount = () => {
    setLoading(true);
    GetAllCount()
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {
        console.log("ERROR", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getOrdersCount();
  }, []);
  return (
    <div className="info-card-container col-6 col-lg-4">
      <h5 className="info-card-title m-0 p-0 ">Orders</h5>
      <div className="mt-3 d-flex flex-column">
        <span className="info-card-sub m-0 p-0 ">
          Total Orders Received :{" "}
          <b>
            {loading ? <Spinner size="sm" animation="border" /> : data?.total}
          </b>
        </span>
        <span className="info-card-sub m-0 p-0 ">
          Pending :{" "}
          <b>
            {loading ? <Spinner size="sm" animation="border" /> : data?.pending}
          </b>
        </span>
        <span className="info-card-sub m-0 p-0 ">
          Complete :{" "}
          <b>
            {loading ? <Spinner size="sm" animation="border" /> : data?.done}
          </b>
        </span>
      </div>
    </div>
  );
};

export default InforCard;
