import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  casualSubCategory,
  categories,
  compressionSubCategory,
  sportsSubCategory,
  sublimationSubCategory,
} from "./constants";
import { toastMessage } from "../../components/toast";
import { AddProduct } from "../../services/productService";
import { Spinner } from "react-bootstrap";

const AddProductModal = ({
  show,
  handleClose,
  allProducts,
  setAllProducts,
}) => {
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);

  const [category, setCategory] = useState(categories[0]);
  const [subCategories, setSubCategories] = useState(sportsSubCategory);
  const [subCategory, setSubCategory] = useState(sportsSubCategory[0]);
  const [feature, setFeature] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setCategory(e.target.value);
    if (e.target.value === categories[0]) {
      setSubCategories(sportsSubCategory);
    } else if (e.target.value === categories[1]) {
      setSubCategories(casualSubCategory);
    } else if (e.target.value === categories[2]) {
      setSubCategories(compressionSubCategory);
    } else if (e.target.value === categories[3]) {
      setSubCategories(sublimationSubCategory);
    }
  };

  const handleSubmit = () => {
    if (title != "" && file) {
      setLoading(true);
      let formData = new FormData();
      formData.append("title", title);
      formData.append("categories", category);
      formData.append("subCategory", subCategory);
      formData.append("feature", feature);
      formData.append("file", file);

      AddProduct(formData)
        .then((data) => {
          if (data) {
            let temp = [...allProducts];
            temp.unshift(data);
            setAllProducts(temp);
            toastMessage("success", "Product Added");
            handleClose();
          }
        })
        .catch((err) => {
          console.log("ERROR", err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toastMessage("error", "Please fill all details");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="mdModal">
      <Modal.Header closeButton>
        <Modal.Title>Add Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-center">
            <label className="detail-prod-name">Title: </label>
            <label className="ms-2 detail-prod-name-bold">
              <input
                placeholder="Enter Title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </label>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <label className="detail-prod-name">Category: </label>
            <label className="ms-2 detail-prod-name-bold">
              <select value={category} onChange={handleChange}>
                {categories.map((itm, inx) => {
                  return (
                    <option value={itm} key={inx}>
                      {itm}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between my-4 gap-5">
          <div className="d-flex align-items-center justify-content-start w-50">
            <label className="detail-prod-name">Sub-Categories: </label>
            <label className="ms-2 detail-prod-name-bold">
              <select
                value={subCategory}
                onChange={(e) => {
                  setSubCategory(e.target.value);
                }}
              >
                {subCategories.map((itm, inx) => {
                  return (
                    <option value={itm} key={inx}>
                      {itm}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="d-flex align-items-center justify-content-start w-50">
            <label className="detail-prod-name">File: </label>
            <label className="ms-2 detail-prod-name-bold">
              <input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </label>
          </div>
        </div>
        <input
          type="checkbox"
          value={feature}
          onChange={(e) => {
            setFeature(!feature);
          }}
          id="ff"
        />
        <label htmlFor="ff" className="ms-2">
          {" "}
          Feature Product
        </label>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleSubmit();
          }}
        >
          {loading ? (
            <Spinner size="sm" animation="border" style={{ color: "white" }} />
          ) : (
            "Add"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddProductModal;
