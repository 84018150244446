import React from "react";
import Header from "../../shared/components/header";
import InforCard from "../../shared/components/infoCard";

const Home = () => {
  return (
    <>
      <Header active="Home" />
      <div className="container py-4">
        <h2 className="login-title mt-5 mb-4 text-align-left">Dashboard</h2>
        <div className="d-flex">
          <InforCard />
        </div>
      </div>
    </>
  );
};

export default Home;
