import { HTTP_CLIENT } from "../utils/interceptor";
export const GetAllOrders = () => {
  return HTTP_CLIENT.get("orders/");
};
export const GetAllCount = () => {
  return HTTP_CLIENT.get("orders/getAllCount");
};
export const DeleteOrder = (params) => {
  return HTTP_CLIENT.delete(`orders/${params}`);
};
export const UpdateOrder = (params, obj) => {
  return HTTP_CLIENT.put(`orders/${params}`, obj);
};
