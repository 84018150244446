import React, { useState } from "react";
import { Form, FloatingLabel, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { LoginVS } from "../../shared/utils/validation";
import { LoginArt, LogoIcon } from "../../assets";
import { LoginUser } from "../../shared/services/authService";
import { setUser } from "../../shared/redux/reducers/userSlice";
import { toastMessage } from "../../shared/components/toast";
import "./style.css";
const Login = () => {
  const initialValues = {
    email: "",
    password: "",
  };
  const dispatch = useDispatch();
  const handleSubmit = (values, action) => {
    let params = {
      email: values?.email,
      password: values?.password,
    };
    LoginUser(params)
      .then(({ data }) => {
        let resp = {
          isLoggedIn: true,
          token: data.token,
          user: data.user,
        };
        toastMessage("success", "Logged In Successfully");
        dispatch(setUser(resp));
      })
      .catch((err) => {
        console.log("ERROR", err);
        toastMessage("Error", err);
      })
      .finally(() => {
        action.setSubmitting(false);
      });
  };
  return (
    <div className="container ">
      <div className="row login-main-container">
        <div className="col-10 col-md-8 col-lg-6">
          <img src={LoginArt.default} class="img-fluid" alt="Login image" />
        </div>
        <div className="col-10 col-md-6 col-lg-4">
          <h2 className="login-title pt-5 pb-3">Sign In</h2>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, action) => {
              action.setSubmitting(true);
              handleSubmit(values, action);
            }}
            validationSchema={LoginVS}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="position-relative">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email address"
                    className="mb-4"
                  >
                    <Form.Control
                      type="email"
                      placeholder="you@example.com"
                      onChange={handleChange("email")}
                    />
                  </FloatingLabel>
                  <div className="error">
                    {touched.email && errors.email ? errors.email : ""}
                  </div>
                </div>
                <div className="position-relative">
                  <FloatingLabel controlId="floatingPassword" label="Password">
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      onChange={handleChange("password")}
                    />
                  </FloatingLabel>
                  <div className="error">
                    {touched.password && errors.password ? errors.password : ""}
                  </div>
                </div>
                <button className="mt-4 login-btn" onClick={handleSubmit}>
                  {isSubmitting ? (
                    <Spinner
                      animation="border"
                      color="white"
                      className="spinner"
                      size="sm"
                    />
                  ) : (
                    "Log In"
                  )}
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
