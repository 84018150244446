import axios from "axios";
import { store } from "../redux/store";
import { BaseURL } from "./endpoints";
export const HTTP_CLIENT = axios.create({
  baseURL: BaseURL,
});
const setupAxios = () => {
  HTTP_CLIENT.interceptors.request.use(
    (config) => {
      const token = store.getState().root?.user?.token;
      if (token) {
        config.headers["x-auth-token"] = `${token}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );
};
export const initialConfig = () => {
  setupAxios();
};

initialConfig();
