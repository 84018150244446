import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CoverURL } from "../../utils/endpoints";

const ProductDetailModal = ({ show, handleClose, handleFeature, item }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Product Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-start">
          <div className="d-flex flex-column gap-2  align-items-start justify-content-center w-100">
            <img
              src={item?.cover ? CoverURL + item?.cover : ""}
              className="imgStyle"
            />
            <h5>{item?.title}</h5>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <label className="detail-prod-name">Category: </label>
            <label className="ms-2 detail-prod-name-bold">
              {item?.categories}
            </label>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <label className="detail-prod-name">Sub-Category: </label>
            <label className="ms-2 detail-prod-name-bold">
              {item?.subCategory}
            </label>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <label className="detail-prod-name">Feature: </label>
            <label className="ms-2 detail-prod-name-bold">
              {item?.feature ? "true" : "false"}
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleClose();
            handleFeature();
          }}
        >
          {item?.feature ? "Remove from feature" : "Feature"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductDetailModal;
