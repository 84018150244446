import React, { useState } from "react";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import SideNav from "./sideNav";
import { LogoIcon } from "../../../assets";
import "./style.css";
import { resetUser } from "../../redux/reducers/userSlice";
import { useDispatch } from "react-redux";
const Header = (props) => {
  const dispatch = useDispatch();
  const [offCanvas, setOffCanvas] = useState(false);
  const openSideNav = () => setOffCanvas(true);
  const closeSideNav = () => setOffCanvas(false);
  const handleLogout = () => {
    dispatch(resetUser());
  };
  return (
    <div className="top-level-header  d-flex justify-content-between align-items-center">
      <div className="container d-flex justify-content-between align-items-center">
        <img src={LogoIcon.default} className="logo-icon-style" />
        <div className="d-flex align-items-center">
          <ul className="d-lg-flex d-none header-list p-0 m-0">
            <li>
              <Link
                className={`d-flex flex-column align-items-center justify-content-center header-list-item`}
                to="/home"
              >
                <small
                  className={`font-weight-light  ${
                    props?.active == "Home" ? "active" : ""
                  }`}
                >
                  Home
                </small>
              </Link>
            </li>
            <li>
              <Link
                className="d-flex flex-column align-items-center justify-content-center header-list-item"
                to="/orders"
              >
                <small
                  className={`font-weight-light  ${
                    props?.active == "Orders" ? "active" : ""
                  }`}
                >
                  Orders
                </small>
              </Link>
            </li>
            <li>
              <Link
                className="d-flex flex-column align-items-center justify-content-center header-list-item"
                to="/products"
              >
                <small
                  className={`font-weight-light  ${
                    props?.active == "Products" ? "active" : ""
                  }`}
                >
                  Products
                </small>
              </Link>
            </li>
          </ul>
          <button className="logout-btn" onClick={handleLogout}>
            Log out
          </button>
          <div className="menu-bar d-lg-none d-flex">
            <i className="hamburger" onClick={openSideNav}>
              <FeatherIcon icon="menu" style={{ color: "white" }} size={26} />
            </i>
          </div>
        </div>

        <SideNav
          offCanvas={offCanvas}
          closeSideNav={closeSideNav}
          active={props?.active}
        />
      </div>
    </div>
  );
};

export default Header;
