import { HTTP_CLIENT } from "../utils/interceptor";
export const GetAllProducts = () => {
  return HTTP_CLIENT.get("products/");
};
export const AddProduct = (obj) => {
  return HTTP_CLIENT.post(`products/`, obj);
};
export const DeleteProduct = (params) => {
  return HTTP_CLIENT.delete(`products/${params}`);
};
export const UpdateProduct = (params, obj) => {
  return HTTP_CLIENT.put(`products/${params}`, obj);
};
