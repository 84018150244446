import React, { useEffect } from "react";
function Layout(props) {
  useEffect(() => {
    document.title = props.title + " | Admin SS";
  });
  //

  return (
    <>
      <div style={{ minHeight: "100vh" }}>
        <props.component />
      </div>
    </>
  );
}

export default Layout;
