import React, { useState } from "react";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import "./style.css";
import { LogoIcon } from "../../../../assets";

const SideNav = ({ offCanvas, closeSideNav, active }) => {
  return (
    <>
      <div
        className={`offcanvas-backdrop ${offCanvas ? "show" : "fade"}`}
        onClick={closeSideNav}
      />
      <div
        role="dialog"
        aria-modal="true"
        className={`offcanvas offcanvas-end ${offCanvas && "show"}`}
        tabIndex="1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div className="offcanvas-header">
          <div className="offcanvas-title" id="offcanvasNavbarLabel">
            <Link to="/" onClick={closeSideNav}>
              <img src={LogoIcon.default} alt="logo" width="100" />
            </Link>
          </div>
          <i className="times" onClick={closeSideNav}>
            <FeatherIcon icon="x" style={{ color: "white" }} />
          </i>
        </div>
        <div className="offcanvas-body">
          <div className="justify-content-end flex-grow-1 ps-3 navbar-nav">
            <ul className="d-flex flex-column header-list p-0 m-0">
              <li>
                <Link
                  className="d-flex flex-row align-items-center  header-list-item pb-3"
                  to="/home"
                  onClick={closeSideNav}
                >
                  <small
                    className={`font-weight-light  ${
                      active == "Home" ? "active" : ""
                    }`}
                  >
                    Home
                  </small>
                </Link>
              </li>

              <>
                <li>
                  <Link
                    className="d-flex flex-row align-items-center  header-list-item pb-3"
                    to="/orders"
                    onClick={closeSideNav}
                  >
                    <small
                      className={`font-weight-light  ${
                        active == "Article" ? "active" : ""
                      }`}
                    >
                      Orders
                    </small>
                  </Link>
                </li>
              </>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideNav;
