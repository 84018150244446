const categories = [
  "Sports Wear",
  "Casual Wear",
  "Compression Wear",
  "Sublimation Wear",
];

const sportsSubCategory = [
  "Baseketball Uniforms",
  "Baseball Uniforms",
  "Goalkeeper Uniforms",
  "Soccer Uniforms",
  "Volleyball Uniforms",
  "Afl Uniforms",
  "Ice Hockey",
  "Cricket Uniforms",
  "Lacrosse Uniforms",
  "Rugby Uniforms",
];

const casualSubCategory = [
  "Puffer Jackets",
  "Casual T - Shirts",
  "Hoodies",
  "Polo Shirt",
  "Sweat Shirts",
  "Wool Cap",
];

const compressionSubCategory = ["Leggings", "Sports bra"];

const sublimationSubCategory = [
  "Base Netball",
  "Hoodies",
  "Jersey",
  "Shorts",
  "Leggings",
  "Long Sleeve",
  "Short Sleeve",
  "Singlets",
  "Track Suit",
];

export {
  categories,
  sportsSubCategory,
  casualSubCategory,
  compressionSubCategory,
  sublimationSubCategory,
};
