import Login from "../../pages/login";
import Home from "../../pages/home";
import Orders from "../../pages/orders";
import Products from "../../pages/products";

const publicRoute = [
  {
    path: "/",
    title: "Login",
    component: Login,
  },
];
const privateRoute = [
  {
    path: "/home",
    title: "Home",
    component: Home,
  },
  {
    path: "/orders",
    title: "Orders",
    component: Orders,
  },
  {
    path: "/products",
    title: "Products",
    component: Products,
  },
];

export { publicRoute, privateRoute };
