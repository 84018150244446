import React, { useEffect, useState } from "react";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import { Spinner } from "react-bootstrap";
import { DeleteOrder, UpdateOrder } from "../../services/orderService";
import { toastMessage } from "../toast";
import "./style.css";
import { CoverURL } from "../../utils/endpoints";
import DetailModal from "../../modals/detail";

const OrderCard = ({ item, allorders, setAllOrders }) => {
  const [deleteloading, setDeleteLoading] = useState(false);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = () => {
    setDeleteLoading(true);
    DeleteOrder(item?._id)
      .then((data) => {
        if (data) {
          let temp = [...allorders];
          var filterArr = temp.filter((itm) => {
            return itm?._id != item?._id;
          });
          setAllOrders(filterArr);
          toastMessage("success", "Order deleted");
        }
      })
      .catch((err) => {
        console.log("ERROR", err);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };
  const handleComplete = () => {
    setCompleteLoading(true);
    setStatus(!status);
    UpdateOrder(item?._id, { status: !item?.status })
      .then((data) => {
        if (data) {
          toastMessage("success", "Completed Order!");
        }
      })
      .catch((err) => {
        console.log("ERROR", err);
      })
      .finally(() => {
        setCompleteLoading(false);
      });
  };
  useEffect(() => {
    setStatus(item?.status);
  }, [item?._id]);
  return (
    <div className="col-12 justify-content-between d-flex flex-column flex-lg-row align-items-center blog-card-container mt-4">
      <div className="d-flex align-items-center col-12 col-lg-6">
        <img
          src={
            item?.orders[0]?.product?.cover
              ? CoverURL + item?.orders[0]?.product?.cover
              : ""
          }
          className="blog-card-img"
        />
        <div className="d-flex flex-column">
          <h5 className="blog-card-subtitle ms-2 d-flex justify-content-center align-items-center">
            <b>Status:</b>{" "}
            <div className={`mx-2 ${status ? "dot-done" : "dot-pending"} `} />{" "}
            {status ? "Completed" : "Pending"}
          </h5>
          <span className="blog-card-subtitle ms-2">
            {moment(item?.createdAt).fromNow()}
          </span>
        </div>
      </div>
      <div className="d-flex flex-row mt-3 mt-lg-0 flex-lg-column align-items-end col-12 col-lg-6">
        <button
          className="secndry-btn"
          disabled={deleteloading || completeLoading}
          onClick={handleShow}
        >
          <>
            <FeatherIcon icon={"eye"} className="icon-style me-1" />
            View
          </>
        </button>
        <button
          className="secndry-btn"
          disabled={deleteloading || completeLoading}
          onClick={handleComplete}
        >
          {completeLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>{!status ? "Complete" : "Add to Pending"}</>
          )}
        </button>
        <button
          className="secndry-btn"
          disabled={deleteloading || completeLoading}
          onClick={handleDelete}
        >
          {deleteloading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>
              <FeatherIcon icon={"trash"} className="icon-style me-1" />
              Delete
            </>
          )}
        </button>
      </div>
      <DetailModal
        show={show}
        handleClose={handleClose}
        handleComplete={handleComplete}
        item={item}
      />
    </div>
  );
};

export default OrderCard;
